<template>
  <div class="sliderBlock">
    <div class="amount">
      <span v-show="!inputMode" @click="amountInputMode">{{amount | currency(mode)}}</span>
      <input ref="amountIn" v-show="inputMode" type="number" @keypress.enter="amountInput" @blur="amountInput"/>
    </div>
    <div class="title">{{title}}</div>
    <div class="subTitle light">{{subTitle}}</div>
    <div class="ques">{{ques}}</div>
    <div class="hint light">{{hint}}</div>
    <div class="range">
      <input type="range" v-model="amount" :min="min" :max="max" :step="step">
      <div class="min-max">
        <div class="min">{{min | currency(mode)}}</div>
        <div class="max">{{max | currency(mode)}}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: String,
    subTitle: String,
    ques: String,
    hint: String,
    mode: Number,
    step: Number,
    min: Number,
    max: Number
  },
  data() {
    return {
      amount: 0,
      inputMode: false
    };
  },
  mounted() {
    this.amount = Math.ceil(
      Math.random() * (this.$props.max - this.$props.min) + this.$props.min
    );
  },
  methods: {
    amountInputMode() {
      this.inputMode = true;
      this.$refs.amountIn.value = this.amount;
      setTimeout(() => {
        this.$refs.amountIn.focus();
      }, 20);
    },
    amountInput() {
      if (
        this.$refs.amountIn.value == null ||
        this.$refs.amountIn.value == "" ||
        typeof this.$refs.amountIn.value == NaN
      ) {
        this.$refs.amountIn.value = this.$props.min;
      } else if (this.$refs.amountIn.value > this.$props.max) {
        this.$refs.amountIn.value = this.$props.max;
      } else if (this.$refs.amountIn.value < this.$props.min) {
        this.$refs.amountIn.value = this.$props.min;
      }
      this.inputMode = false;
      this.amount = Number(this.$refs.amountIn.value).toFixed(this.mode);
    }
  },
  watch: {
    amount() {
      this.$emit("changing");
    }
  }
};
</script>

<style lang="scss" scoped>
@import "../assets/scss/style.scss";
@import "../assets/scss/slider.css";

.sliderBlock {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 20px 0;
  div {
    font-weight: 700;
    text-align: left;
    @include font(smaller);
    &.light {
      font-weight: 300;
    }
  }
  .amount {
    position: absolute;
    color: $mainRed;
    height: 10%;
    top: 3%;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    span {
      cursor: pointer;
      @include font(large);
      @media #{$slim} {
        font-size: 20px;
      }
    }
    input {
      height: 10px;
      width: 180px;
      padding: 10px 5px;
      @include font(small);
      border-radius: 5px;
    }
  }
  .title,
  .subtitle {
    @include font(small);
  }
  .ques {
    margin: 25px 0;
  }
  .range {
    width: 100%;
    margin: 25px 0;
    .min-max {
      margin-top: 15px;
      display: flex;
      justify-content: space-between;
    }
    input[type="range"]::-webkit-slider-thumb {
      -webkit-appearance: none;
      width: 25px;
      height: 25px;
      border-radius: 100%;
    }
  }
}
</style>
