<template>
  <div class="mediaAds">
    <router-view />
    <template v-if="!inAdPage">
    <header>
      <top-title
      title="Media Buy"
      subTitle="媒體廣告投放">
      </top-title>
    </header>
    <h2>我的數位廣告自我診斷</h2>
    <h3>Self-diagnose</h3>
    <div class="container">
      <div class="options">
        <slider-block ref="PMB" @changing="change"
          :min="0" :max="1000000" :step="5000" :mode="0"
          title="預計每月預算" subTitle="Projected Monthly Budget"
          ques="你預計一個月花多少時間在廣告上呢?"
          hint="如果你尚未不確定要花多少預算在廣告上，那麼你可使用你目前總營銷的 20% - 25%，當做是一個推廣新客的起始點。輸入所有指標後，在返回看一下預算，讓你明白你應該從何開始?" >
        </slider-block>
        <slider-block ref="CPC" @changing="change"
          :min="3" :max="30" :step="1" :mode="0"
          title="預計的 CPC (PPC)" subTitle="Expected CPC"
          ques="你願意為一個點擊付出多少錢呢?"
          hint="所謂的 CPC 就是一個造訪成本願意花多少費用，根據不同產業與類別我們建議從 3 元到 30 元甚至更多。" >
        </slider-block>
        <slider-block ref="TCR" @changing="change"
          :min="0.1" :max="50" :step="0.1" :mode="1"
          title="目標轉換率" subTitle="Target Conversion Rate"
          ques="你認為造訪你網站的消費者大概多久會購買成為訂單呢?"
          hint="轉換率就如同提袋率，多少人進入成交了幾筆購物袋，根據我們 10 年來的電子商務數據，平均轉換率為 2.6% 甚至更多，主要是商品、動線、活動、速度等因素影響客戶購買意願。" >
        </slider-block>
        <slider-block ref="ASP" @changing="change"
          :min="100" :max="10000" :step="100" :mode="0"
          title="平均客單價" subTitle="Average Sale Price"
          ques="你認為你的商品 / 服務，平均的訂單成本是多少?"
          hint="對許多公司而言，這個數字可能會隨著時間變化而不同，例如新客戶與客戶消費生命週期的影響。" >
        </slider-block>
      </div>
      <div class="results">
        <div class="result">
          <h2>Results</h2>
          <h2 class="light">預估結果</h2>
          <hr>
        </div>
        <div class="numOfClick">
          <h3>Number of Clicks</h3>
          <h3 class="light">點擊數</h3>
          <h3 class="number">{{numOfClick | setNum | pureCurrency}}</h3>
          <hr>
        </div>
        <div class="numOfLeads">
          <h3>Number of Leads</h3>
          <h3 class="light">訂單 / 名單 / 行動數量</h3>
          <h3 class="number">{{numOfLeads | setNum | pureCurrency}}</h3>
          <hr>
        </div>
        <div class="costPerLead">
          <h3>Cost per Leads</h3>
          <h3 class="light">訂單 / 名單 / 行動成本</h3>
          <h3 class="number">{{costPerLead  | setNum | currency}}</h3>
          <hr>
        </div>
        <div class="expextedRevenue">
          <h3>Expexted Revenue</h3>
          <h3 class="light">預估收入</h3>
          <h3 class="number">{{expextedRevenue  | setNum | currency}}</h3>
        </div>
        <div class="costPerRevenue">
          <h3>Cost per Revenue</h3>
          <h3 class="light">本益率</h3>
          <h1 class="number white">{{costPerRevenue}}</h1>
        </div>
      </div>
    </div>
    <h2>從不同的社群媒體了解廣告行銷</h2>
    <h3>Learn about advertising from different social media</h3>
    <div class="socialMedias">
      <router-link class="Ads" :to="{name: 'FBAds'}">
        <div class="FBAds">
          <span>FB 廣告</span>
        </div>
      </router-link>
      <router-link class="Ads" :to="{name: 'LineAds'}">
        <div class="LineAds">
          <span>LINE 廣告</span>
        </div>
      </router-link>
      <router-link class="Ads" :to="{name: 'GoogleAds'}">
        <div class="GoogleAds">
          <span>Google 廣告</span>
        </div>
      </router-link>
    </div>
    </template>
    <footer-view />
  </div>
</template>

<script>
import topTitle from "@/components/topTitle.vue";
import sliderBlock from "@/components/sliderBlock.vue";
import footerView from "@/components/footerView.vue";

export default {
  name: "mediaAds",
  components: {
    topTitle,
    sliderBlock,
    footerView
  },
  data() {
    return {
      PMB: 0,
      CPC: 0,
      TCR: 0,
      ASP: 0,
      inAdPage: false
    };
  },
  computed: {
    numOfClick() {
      return Number(this.PMB) / Number(this.CPC);
    },
    numOfLeads() {
      return Number(this.PMB) / Number(this.ASP);
    },
    costPerLead() {
      return Number(this.PMB) / Number(this.numOfLeads);
    },
    expextedRevenue() {
      return (
        Number(this.TCR) *
        0.01 *
        Number(this.ASP) *
        Number(this.numOfClick)
      ).toFixed(0);
    },
    costPerRevenue() {
      var e =
        ((Number(this.PMB) / this.expextedRevenue).toFixed(2) * 100).toString()
          .length >= 4
          ? ((Number(this.PMB) / this.expextedRevenue).toFixed(2) * 100)
              .toString()
              .split("")
              .slice(0, 4)
              .join("")
          : (Number(this.PMB) / this.expextedRevenue).toFixed(2) * 100;
      isNaN(e) ? (e = 0) : "";
      return e + "%";
    }
  },
  created() {
    window.addEventListener("scroll", this.handleScroll);
  },
  mounted() {
    this.$parent.inPage = true;
  },
  destroyed() {
    this.$parent.inPage = false;
    window.removeEventListener("scroll", this.handleScroll);
  },
  methods: {
    handleScroll() {
      var st = window.pageYOffset || document.documentElement.scrollTop;
      var vm = this;
      var hook = window.innerHeight;
      if (!this.inAdPage) {
        if (st > $(".options").offset().top - 102) {
          $(".results").addClass("fixed");
          if (st > $(".options").offset().top + $(".results").innerHeight()) {
            $(".results").addClass("fixDown");
          } else {
            $(".results").removeClass("fixDown");
          }
        } else {
          $(".results").removeClass("fixed");
          $(".results").removeClass("fixDown");
        }
        $(".Ads").each(function() {
          if (
            st > $(this).offset().top - hook * 0.6 &&
            st < $(this).offset().top - hook * 0.3
          ) {
            $(this).addClass("hover");
          } else {
            $(this).removeClass("hover");
          }
        });
      }
    },
    change() {
      this.PMB = this.$refs.PMB.amount;
      this.CPC = this.$refs.CPC.amount;
      this.TCR = this.$refs.TCR.amount;
      this.ASP = this.$refs.ASP.amount;
    }
  },
  watch: {
    inAdPage() {
      $("html, body").animate({ scrollTop: 0 }, 0);
    }
  }
};
</script>

<style lang="scss" scoped>
@import "../../assets/scss/style.scss";

header {
  background-image: url("../../assets/image/service/mediaAds/topBanner.jpg");
}
h2,
h3 {
  margin: 0;
}
h2 {
  margin-top: 20px;
}
h3 {
  margin-bottom: 40px;
}

.mediaAds {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.container {
  position: relative;
  display: flex;
  justify-content: space-between;
  width: 85vw;
  .options {
    width: 80%;
    align-self: flex-start;
  }
  .results {
    $resultRight: 15vw;
    position: absolute;
    left: 85vw - $resultRight;
    width: 20vw;
    max-width: 280px;
    border: 1px solid $mainRed;
    border-radius: 8px;
    &.fixed {
      position: fixed;
      width: 20vw;
      top: 102px;
      left: calc(92.5vw - #{$resultRight});
      &.fixDown {
        position: absolute;
        top: auto;
        bottom: 0;
        left: 85vw - $resultRight;
      }
    }
    hr {
      background-color: $mainRed;
    }
    div {
      padding: 0 12px;
      h1 {
        margin: 0;
        @include font(large);
      }
      h2 {
        margin: 0;
        @include font(small);
      }
      h3 {
        margin: 0;
        @include font(smaller);
      }
      .light {
        font-weight: 300;
      }
      .number {
        padding: 2% 0;
        color: $mainRed;
        &.white {
          color: #fff;
        }
      }
    }
    .result {
      padding-top: 12px;
    }
    .costPerRevenue {
      padding-top: 12px;
      background-color: $mainRed;
      border-radius: 0 0 6px 6px;
      color: #fff;
    }
  }
  .socialMedias {
    width: 88vw;
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    margin-bottom: 40px;
    a {
      position: relative;
      $minSquareSize: 300px;
      width: $minSquareSize;
      height: $minSquareSize;
      margin: 10px;
      box-shadow: 0px 0px 20px 1px #707070;
      transition: 0.3s;
      text-decoration-style: none;
      color: #707070;
      display: flex;
      justify-content: center;
      align-items: center;
      overflow: hidden;
      :nth-child(n) {
        position: absolute;
        transition: 0.3s;
      }
      div {
        height: 100%;
        width: 100%;
        background-repeat: no-repeat;
        background-position: center;
        background-color: #fff;
        &.FBAds {
          background-image: url("../../assets/image/service/mediaAds/FBAds.png");
          background-size: 10%;
        }
        &.LineAds {
          background-image: url("../../assets/image/service/mediaAds/LineAds.png");
        }
        &.GoogleAds {
          background-image: url("../../assets/image/service/mediaAds/GoogleAds.png");
          background-size: 20%;
        }
      }
      span {
        white-space: nowrap;
        opacity: 0;
        bottom: 40%;
        left: 0;
        right: 0;
        z-index: 2;
        @include font(small);
      }
      &:hover {
        transform: translate(-2px, -2px);
        box-shadow: 2px 2px 20px 1px #707070;
        div {
          transform: translateY(-3%);
        }
        span {
          opacity: 1;
          bottom: 25%;
        }
      }
      @media #{$tablet} {
        &.hover {
          transform: translate(-2px, -2px);
          box-shadow: 2px 2px 20px 1px #707070;
          div {
            transform: translateY(-3%);
          }
          span {
            opacity: 1;
            bottom: 25%;
          }
        }
      }
    }
  }
  @media #{$tablet} {
    width: 95vw;
    flex-direction: column;
    .options {
      width: 100%;
    }
    .results {
      position: relative !important;
      width: 100% !important;
      left: auto !important;
      top: auto !important;
      bottom: 0;
      align-self: center;
    }
  }
}
</style>
